import React from "react";
import MyCarousel from "../carousel";
import Banner from "./banner";
import OverView from "./OverView";
import ProjectHighlights from "./ProjectHighlights";
import ProjectPlans from "./ProjectPlans";
import Aminities from "./Aminities";
import Gallery from "./Gallery";
import Location from "./Location";

const Main = () => {
  return (
    <>
      <Banner />
      <OverView />
      <ProjectHighlights />
      <ProjectPlans />
      <Aminities />
      <Gallery />
      <Location />
    </>
  );
};

export default Main;
