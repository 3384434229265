import React, { useState, useRef } from "react";
import { EMAILJS_DATA } from "./constant";
import emailjs from "@emailjs/browser";

const BannerModal = () => {
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
  });

  const form = useRef();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const sendEmail = (e) => {
    e.preventDefault();
    setLoading(true);
    emailjs
      .send(
        EMAILJS_DATA.serviceId,
        EMAILJS_DATA.templateId,
        formData,
        EMAILJS_DATA.publicKey,
      )
      .then(
        () => {
          alert("We have received your request!");
          setLoading(false);
          setFormData({
            name: "",
            email: "",
            phone: "",
          });
          form.current.reset();
        },
        (error) => {
          alert("FAILED...", error);
          console.log(error);
          setLoading(false);
        },
      );
  };

  return (
    <div className="banner_form d-md-block wow fadeInUp rounded-3">
      <div className="modalButton">
        <h5 className="mb-3">Request a callback</h5>
      </div>

      <form ref={form} onSubmit={sendEmail}>
        <div className="form-group mb-2 mb-lg-3">
          <input
            type="text"
            name="name"
            id="name"
            placeholder="Name*"
            className="form-control"
            required
            onChange={handleChange}
          />
        </div>
        <div className="form-group mb-2 mb-lg-3">
          <input
            type="email"
            name="email"
            id="email"
            placeholder="Email*"
            className="form-control"
            required
            onChange={handleChange}
          />
        </div>
        <div className="form-group mb-2 mb-lg-3">
          <input
            type="tel"
            name="phone"
            id="phone"
            placeholder="Enter Phone Number"
            className="form-control"
            pattern="^[6-9][0-9]{9}$"
            required
            autoComplete="off"
            onChange={handleChange}
          />
        </div>
        <button
          className="btn w-100 btn-txt"
          type="submit"
          id="submitBtn1"
          disabled={loading}
        >
          {loading ? (
            <div
              className="spinner-border spinner-border-sm text-light"
              role="status"
            ></div>
          ) : (
            "Submit"
          )}
        </button>
      </form>
    </div>
  );
};

export default BannerModal;
