import React from "react";
import EF_FF from "../../components/utils/assets/images/carousel/EF_FF.jpg";
import EF_GF from "../../components/utils/assets/images/carousel/EF_GF.jpg";
import WF_FF from "../../components/utils/assets/images/carousel/WF_FF.jpg";
import WF_GF from "../../components/utils/assets/images/carousel/WF_GF.jpg";
import master_plan_img from "../../components/utils/assets/images/master_plan.jpg";
import MyCarousel from "../carousel";

const ProjectPlans = () => {
  return (
    <section
      className="master_sec pt-3 pb-4 py-md-5 text-center"
      id="projectplans"
    >
      <div className="container">
        <div className="sec_head wow fadeInUp">
          <h3>PROJECT PLANS</h3>
        </div>
        <p className="mb-4 wow fadeInUp">
          Experience the blend of perfection with integrity.
        </p>
        <div className="row gy-md-0 gy-3">
          <div className="col-md-6">
            <div className="master_div rounded-3 h-100">
              <h6 className="mb-0 wow fadeInUp">Master Plan</h6>
              <div className="my-lg-4 my-3 mx-lg-5 mx-4">
                <div className="master_img_div position-relative mx-auto wow fadeInUp">
                  <a
                    href="image"
                    data-fancybox="Master-info"
                    data-caption="Master Plan"
                  >
                    <img
                      class="master_img img-fluid"
                      src={master_plan_img}
                      alt="image"
                    />
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-6">
            <div className="master_div rounded-3 h-100">
              <h6 className="mb-0 wow fadeInUp">Floor Plan</h6>
              <div className="my-lg-4 my-3 mx-lg-5 mx-4">
                <div className="master_img_div position-relative mx-auto wow fadeInUp">
                  <MyCarousel images={[EF_GF, EF_FF, WF_FF, WF_GF]} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ProjectPlans;
