export const HEADER_DETAILS = [
  "Overview",
  "Highlights",
  "ProjectPlans",
  "Amenities",
  "Gallery",
  "Location",
];

export const EMAILJS_DATA = {
  serviceId: "service_xwx622j",
  templateId: "template_23eq1ro",
  publicKey: "n2PBBjuQAe22udJvp",
};
