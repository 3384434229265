import React from "react";
import { HEADER_DETAILS } from "../constant";
import logo from "../../../src/components/utils/assets/images/logo.jpg";
import callButton from "../../../src/components/utils/assets/images/call_btn_icon.svg";
import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";
const Header = () => {
  return (
    <>
      <Navbar bg="light" expand="lg" sticky="top">
        <div className="container">
          <Navbar.Brand href="#" aria-label="APR Hillside">
            <img src={logo} className="img-fluid" alt="logo" />
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="navbarSupportedContent" />
          <Navbar.Collapse id="navbarSupportedContent">
            <Nav className="ms-auto mb-2 mb-lg-0 align-items-center">
              {HEADER_DETAILS.map((value) => (
                <Nav.Item key={value}>
                  <Nav.Link
                    href={`#${value.toLowerCase()}`}
                    className="menu-link"
                  >
                    {value}
                  </Nav.Link>
                </Nav.Item>
              ))}
              <Nav.Item className="d-none d-md-flex">
                <a
                  href="tel:+919866289888"
                  style={{ border: "none", marginLeft: "10px" }}
                >
                  <button
                    type="button"
                    className="btn btn-rounded-3"
                    style={{ marginRight: "10px" }}
                  >
                    <img src={callButton} className="img-fluid" alt="call" />
                    9866289888
                  </button>
                </a>
              </Nav.Item>
              <Nav.Item className="d-none d-md-flex">
                <Nav.Link href={"#modalPopUp"} className="menu-link">
                  <button
                    type="button"
                    className="btn btn-rounded-3"
                    style={{ marginLeft: "10px" }}
                  >
                    Enquire Now
                  </button>
                </Nav.Link>
              </Nav.Item>
            </Nav>
          </Navbar.Collapse>
        </div>
      </Navbar>
      {/* Mobile view buttons */}
      <div className="d-flex d-md-none fixed-bottom bg-light p-2 justify-content-around">
        <a href="tel:+919866289888" style={{ border: "none" }}>
          <button type="button" className="btn btn-rounded-3">
            <img src={callButton} className="img-fluid" alt="call" />
            9866289888
          </button>
        </a>
        <Nav.Link href={"#modalPopUp"} className="menu-link">
          <button type="button" className="btn btn-rounded-3">
            Enquire Now
          </button>
        </Nav.Link>
      </div>
    </>
  );
};

export default Header;
